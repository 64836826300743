import React from 'react'
import Carousel from 'react-multi-carousel'
import propTypes from 'prop-types'
import { Container } from 'react-bootstrap'
import ImgOverlay from '../../../app-components/image-overlay'

import './styles.scss'

import 'react-multi-carousel/lib/styles.css'

import portraitImg from '../../../../assets/images/cat-portrait.jpg'
import reportageImg from '../../../../assets/images/cat-reportage.jpg'
import animationImg from '../../../../assets/images/cat-animation.jpg'
import DashedHeading from '../../../app-components/dashed-heading'

const images = [
  {
    img: portraitImg,
    label: 'Portrait',
    description:
      'Véritable reflet de l’identité et de la culture de l’entreprise, le portrait constitue un outil indispensable de votre stratégie de communication visuelle. C’est pourquoi nos photographes professionnels mettent leur savoir-faire au service de votre entreprise.En studio, dans vos locaux ou en extérieur, nous nous adaptons à votre demande pour un rendu optimal',
    link: '/professionnels/portrait',
  },
  {
    img: reportageImg,
    label: 'Reportage',
    description:
      'L’oeil expert du photographe saura capturer les moments-clefs de vos événements. En interne ou sur vos réseaux sociaux avec des images de qualité qui sont vos principaux vecteurs de communication',
    link: '/professionnels/reportage',
  },
  {
    img: animationImg,
    label: 'Animation',
    description:
      'Vous souhaitez animer un événement tel qu’une soirée d’entreprise, un afterwork, un lancement de produit et permettre à vos convives de repartir avec des souvenirs inoubliables ?Photobooth, photocall ou miroir magique : nous avons la solution pour joindre l’utile à l’agréable',
    link: '/professionnels/animation',
  },
]

const CAROUSEL_RESPONSIVE_RULES = {
  'desktop-l': {
    breakpoint: {
      max: 5000,
      min: 1280,
    },
    items: 3,
  },
  desktop: {
    breakpoint: {
      max: 1279,
      min: 1024,
    },
    items: 2,
    partialVisibilityGutter: 90,
  },
  tablet: {
    breakpoint: {
      max: 1023,
      min: 768,
    },
    items: 1,
    partialVisibilityGutter: 320,
  },
  'mobile-l': {
    breakpoint: {
      max: 767,
      min: 411,
    },
    items: 1,
    partialVisibilityGutter: 90,
  },
  'mobile-m': {
    breakpoint: {
      max: 410,
      min: 361,
    },
    items: 1,
    partialVisibilityGutter: 56,
  },
  mobile: {
    breakpoint: {
      max: 360,
      min: 1,
    },
    items: 1,
    partialVisibilityGutter: 40,
  },
}

const CAROUSEL_CHECKOUT_RESPONSIVE_RULES = {
  'desktop-l': {
    breakpoint: {
      max: 5000,
      min: 1280,
    },
    items: 2,
    partialVisibilityGutter: 20,
  },
  desktop: {
    breakpoint: {
      max: 1279,
      min: 1024,
    },
    items: 2,
    partialVisibilityGutter: 20,
  },
  tablet: {
    breakpoint: {
      max: 1023,
      min: 768,
    },
    items: 1,
    partialVisibilityGutter: 15,
  },
  'mobile-l': {
    breakpoint: {
      max: 767,
      min: 411,
    },
    items: 2,
    partialVisibilityGutter: 15,
  },
  'mobile-m': {
    breakpoint: {
      max: 410,
      min: 361,
    },
    items: 2,
    partialVisibilityGutter: 15,
  },
  mobile: {
    breakpoint: {
      max: 360,
      min: 1,
    },
    items: 2,
    partialVisibilityGutter: 15,
  },
}

const ButtonGroup = ({ next, previous, ...rest }) => {
  const {
    carouselState: { currentSlide, slidesToShow, totalItems },
  } = rest
  return (
    <div
      className={`carousel-control-group ${
        slidesToShow === totalItems ? 'hide-arrows' : ''
      }`}
    >
      <span
        aria-hidden="true"
        className={`circle-slider carousel-prev-icon
          ${currentSlide === 0 ? 'disable' : ''}
        `}
        onClick={previous}
      >
        <div className="triangle-prev" />
      </span>
      <span
        aria-hidden="true"
        className={`circle-slider carousel-next-icon
          ${currentSlide >= totalItems - slidesToShow ? 'disable' : ''}
        `}
        onClick={next}
      >
        <div className="triangle-next" />
      </span>
    </div>
  )
}
ButtonGroup.propTypes = {
  next: propTypes.func,
  previous: propTypes.func,
}
ButtonGroup.defaultProps = { next: null, previous: null }

const PortfolioSlider = ({ pictures, imgClass, title, variant }) => (
  <div className={`portfolio-custom-slider ${variant}`}>
    <Container>
      {title && <DashedHeading title={title} align="left" />}
      {pictures && pictures.length !== 0 ? (
        <Carousel
          additionalTransfrom={0}
          customButtonGroup={<ButtonGroup />}
          arrows={false}
          autoPlaySpeed={3000}
          centerMode={false}
          containerClass="slider-wrapper custom-carousel-container"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          partialVisbile="right"
          renderDotsOutside={false}
          responsive={
            variant === 'checkout'
              ? CAROUSEL_CHECKOUT_RESPONSIVE_RULES
              : CAROUSEL_RESPONSIVE_RULES
          }
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {pictures.map(({description, imageFile, title, url}) => (
            <ImgOverlay
              key={title}
              className={`portfolio-img ${imgClass}`}
              img={imageFile?.sourceUrl ?? ''}
              label={title}
              description={description}
              url={url}
            />
          ))}
        </Carousel>
      ) : (
        <Carousel
          additionalTransfrom={0}
          customButtonGroup={<ButtonGroup />}
          arrows={false}
          autoPlaySpeed={3000}
          centerMode={false}
          containerClass="slider-wrapper custom-carousel-container"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          partialVisbile="right"
          renderDotsOutside={false}
          responsive={
            variant === 'checkout'
              ? CAROUSEL_CHECKOUT_RESPONSIVE_RULES
              : CAROUSEL_RESPONSIVE_RULES
          }
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {images.map(({ img, label, description, link }) => (
            <ImgOverlay
              key={label}
              className={`portfolio-img ${imgClass}`}
              img={img}
              label={label}
              description={description}
              url={link}
            />
          ))}
        </Carousel>
      )}
    </Container>
  </div>
)

PortfolioSlider.defaultProps = {
  imgClass: '',
  title: null,
  variant: '',
  pictures: [],
}

PortfolioSlider.propTypes = {
  pictures: propTypes.arrayOf(propTypes.object),
  imgClass: propTypes.string,
  title: propTypes.string,
  variant: propTypes.string,
}
export default PortfolioSlider
