/* eslint-disable react/prop-types, camelcase, react/no-array-index-key, react/no-unescaped-entities */
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import IconBox from '../../app-components/icon-box'

import './styles.scss'

const Services = ({ services }) => (
  <Container className="services">
    <Row className="border-services justify-content-sm-center justify-content-center justify-content-md-between pt-4">
      {services &&
        services.map(({title, acfServices:acf}, index) => (
          <Col key={index} xs="8" sm="6" md="4" lg="3">
            <IconBox acf={acf} title={title} />
          </Col>
        ))}
    </Row>
  </Container>
)

export default Services
