import React from 'react'
import { graphql } from 'gatsby'
import propTypes from 'prop-types'

import Achievements from '../../components/sections/achievements'

import Banner from '../../components/sections/_banner/default'
import Services from '../../components/sections/services'
import PortfolioSlider from '../../components/sections/portfolio/slider'
import SimpleText from '../../components/sections/simpleText'
import Layout from '../../layouts/default'
import './styles.scss'

import imageBanner from '../../assets/images/booke-vertical-yellow-claw.png'

const HubCategoryTemplate = ({
  data: {
    wpPage: { title, template:{acfOffers:acf}, seo },
  },
}) => {
  return (
    <Layout seo={seo}>
      <div className="offres-page">
        <Banner img={imageBanner} title={title} />
        <SimpleText
          className="heading"
          title={acf.simpleTextTitle}
          description={acf.simpleTextDescription}
          button={acf.simpleTextCta}
        />
        <PortfolioSlider pictures={acf.offers} imgClass="portfolio" />
        {parseInt(acf.displayServices, 10) === 1 && acf.services && (
          <Services services={acf.services} />
        )}
        {parseInt(acf.displayRealizations, 10) === 1 && acf.realizations && (
          <Achievements achievements={acf.realizations} />
        )}
        {parseInt(acf.displayServices2, 10) === 1 && acf.servicesBlock2 && (
          <Services services={acf.servicesBlock2} />
        )}
      </div>
    </Layout>
  )
}
HubCategoryTemplate.propTypes = {
  data: propTypes.objectOf(propTypes.any).isRequired,
}
export default HubCategoryTemplate

export const query = graphql`
  query HubCategoryPage($id: String!) {
    wpPage(id: { eq: $id }) {
      template {
        templateName
        ... on WpTemplate_Offers {
          templateName
          acfOffers {
            displayRealizations
            displayServices
            displayServices2
            headerTitle
            headerPicture {
              sourceUrl
              title
            }
            offers {
              url
              title
              imageFile {
                sourceUrl
              }
              description
            }
            realizations {
              ...realizationFragment
            }
            services {
              ...serviceFragment
            }
            servicesBlock2 {
              ...serviceFragment
            }
            simpleTextCta {
              title
              url
            }
            simpleTextTitle
            simpleTextDescription
          }
        }
      }
      title
      ...yoastMeta
    }
  }
`
