/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useMemo } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Slider from 'react-slick'
import propTypes from 'prop-types'
import Link from '../../app-components/link'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Heading from '../../app-components/dashed-heading'

import './styles.scss'

import yellowCircleImg from '../../../assets/images/slider-yellow-back.svg'

function SamplePrevArrow({ className, onClick }) {
  return (
    <div className={className} onClick={onClick}>
      <div className="circle-slider-button">
        <div className="triangle-prev" />
      </div>
    </div>
  )
}

function SampleNextArrow({ className, onClick }) {
  return (
    <div className={className} onClick={onClick}>
      <div className="circle-slider-button">
        <div className="triangle-next" />
      </div>
    </div>
  )
}
const arrowTypes = {
  className: propTypes.string,
  onClick: propTypes.func,
}
const defaultArrowTypes = { className: '', onClick: null }

SamplePrevArrow.propTypes = arrowTypes
SamplePrevArrow.defaultProps = defaultArrowTypes

SampleNextArrow.propTypes = arrowTypes
SampleNextArrow.defaultProps = defaultArrowTypes

const Achievements = ({ achievements }) => {
  const [getFilter, setFilter] = useState('')

  const [pictures, setPictures] = useState([])
  const [client, setClient] = useState('')
  const [demand, setDemand] = useState('')
  const [uniqueId, setUniqueId] = useState(0)

  const settings = {
    infinite: false,
    speed: 500,
    afterChange: () => {
      if (document.querySelector('.slick-active .client-name')) {
        setClient({
          active: document.querySelector('.slick-active .client-name')
            ?.innerText,
        })
        setDemand({
          active: document.querySelector('.slick-active .demand-name')
            ?.innerText,
        })
      } else {
        setClient('')
        setDemand('')
      }
    },
    onInit: () =>
      setTimeout(() => {
        setClient({
          active: document.querySelector('.slick-active .client-name')
            ?.innerText,
        })
        setDemand({
          active: document.querySelector('.slick-active .demand-name')
            ?.innerText,
        })
      }, 1000),
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 540,
        settings: {
          centerMode: true,
        },
      },
    ],
  }
  const realizations = useMemo(() => {
return achievements.map((item) => item?.acf?.realizations)
  }, [achievements])

  useEffect(() => {
    realizations.forEach((element) => {
      setPictures((pics) =>
        element?.length > 0 ? [...pics, element[0]] : pics
      )
    })
  }, [realizations])

  const handleClick = (e) => {
    setFilter(e.target.innerText === 'Tout' ? '' : e.target.innerText)
    for (
      let index = 0;
      index < e.target.parentElement.children.length;
      index += 1
    ) {
      e.target.parentElement.children[index].classList.remove('active')
    }
    e.target.classList.add('active')
    setTimeout(() => {
      if (document.querySelector('.slick-track').children.length > 0) {
        setUniqueId(uniqueId + 1)
      }
    }, 500)
  }
  const styleFilter = {
    cursor: 'pointer',
  }
  return (
    <div className="achievements">
      <Container>
        <Row className="justify-content-between my-4">
          <Col xs={12} md="auto">
            <Heading title="Nos réalisations" className="text-left" />
          </Col>
          <Col xs={12} md="auto" className="align-self-center text-center">
            <ul className="d-flex lists">
              <li
                className="list-item active"
                style={styleFilter}
                onClick={handleClick}
              >
                Tout
              </li>
              <li
                className="list-item"
                style={styleFilter}
                onClick={handleClick}
              >
                Portrait
              </li>
              <li
                className="list-item"
                style={styleFilter}
                onClick={handleClick}
              >
                Reportage
              </li>
              <li
                className="list-item"
                style={styleFilter}
                onClick={handleClick}
              >
                Animation
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container fluid className="position-relative overflow-hidden">
        <Row className="position-relative">
          <Col
            lg={1}
            className="p-0 d-none d-sm-none d-md-none d-lg-flex align-items-center"
          >
            <img className="m-0" src={yellowCircleImg} alt="Bg Yellow circle" />
          </Col>
          <Col className="slider-back" sm={12} md={12} lg={11} key={uniqueId}>
            <div className="caption-text">
              <div>
                <h3 className="slider-title m-0">CLIENT</h3>
                <p className="slider-description">{client?.active}</p>
              </div>
              <div>
                <h3 className="slider-title m-0">DEMANDE</h3>
                <p className="slider-description">{demand?.active}</p>
              </div>
            </div>
            <Slider {...settings}>
              {pictures.map(
                (pic, index) =>
                  (getFilter === '' || getFilter === pic?.request?.name) && (
                    <div key={`realizations${index}`} className="achievements-slide d-flex">
                      <div className="slider-text">
                        <div>
                          <h3 className="slider-title m-0">CLIENT</h3>
                          <p className="slider-description client-name">
                            {pic?.company?.name}
                          </p>
                        </div>
                        <div>
                          <h3 className="slider-title m-0">DEMANDE</h3>
                          <p className="slider-description demand-name">
                            {pic?.request?.name}
                          </p>
                        </div>
                      </div>
                      <Link
                        to="/realisations"
                        className="slider-link icon-booke-show"
                      />
                      <img
                        className="slider-image"
                        alt={pic?.image?.title}
                        src={pic?.image?.sourceUrl}
                      />
                    </div>
                  )
              )}
            </Slider>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
Achievements.propTypes = {
  achievements: propTypes.arrayOf(propTypes.any).isRequired,
}

export default Achievements
