/* eslint-disable react/prop-types, react/no-unescaped-entities,jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import { navigate } from '@reach/router'
import React from 'react'
import Button from '../button'
import './styles.scss'

export default class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showHover: false,
    }
  }

  render() {
    const { showHover } = this.state
    const {
      className,
      img,
      imgClass,
      label,
      description,
      url,
      detailView = null,
      checkbox,
    } = this.props

    const renderDetailView =
      detailView === null ? (
        <div className="overlay hover" onClick={() => navigate(url)}>
          <h4 className="overlay-title">{label}</h4>
          {description && (
            <p className="overlay-description">
              {description.length > 120
                ? `${description.substring(0, 120)}...`
                : description}
            </p>
          )}
          <Button name="DÉCOUVRIR" type="light" to={url} />
        </div>
      ) : (
        detailView
      )

    return (
      <div
        className={`img-overlay ${className}`}
        onMouseEnter={() => this.setState({ showHover: true })}
        onMouseLeave={() => this.setState({ showHover: false })}
      >
        <img
          src={img}
          className={imgClass}
          alt={label ? `Exemple de l'offre ${label}` : `Aperçu`}
        />
        {!showHover && (
          <div className="overlay">
            <h4 className="overlay-title">{label}</h4>
          </div>
        )}
        <span
          className="icon-info"
          onClick={() => this.setState({ showHover: !showHover })}
        />
        {showHover && renderDetailView}
        {checkbox && checkbox}
      </div>
    )
  }
}
