/* eslint-disable react/prop-types, react/no-danger */
import React from 'react'
import './styles.scss'
import Icon from '../icon'

export default function ({ label, acf }) {
  return (
    <div className="icon-box">
      {acf.serviceIconType === 'file' && acf.serviceSvgImage && (
        <img
          src={acf.serviceSvgImage?.sourceUrl}
          alt={label}
          className="img-fluid service-img"
        />
      )}
      {acf.serviceIconType === 'icon' && acf.serviceIconName && (
        <Icon
          iconClass="service-icon"
          name={acf.serviceIconName}
          paths={parseInt(acf.serviceIconNbPath, 10)}
        />
      )}
      <h2 className="icon-title mt-4">{label}</h2>
      <div
        className="icon-desc"
        dangerouslySetInnerHTML={{ __html: acf.serviceDescription }}
      />
    </div>
  )
}
